<template>
	<div class="hotel-indexv2" @click="on2()">
		<div class="hotel-indexv2-banner">
			<div class="hotel-banner">
				<el-form>
					<!--<el-tabs v-model="activeName" @tab-click="handleClick" class="hotel-b">-->
					<!--<el-tab-pane :label="data" name="first">-->
					<el-row type="flex" justify="end">
						<div class="position-r" style="padding-right:40px;">
							<span class="ft14 mr10" @click="bindShowPerson">
								{{ HotelSearchInfo.RoomCount }}间房、{{ HotelSearchInfo.AdultCount + HotelSearchInfo.ChildCount }}位旅客
							</span>
							<span class="iconfont icon-jiantouxiamian"></span>
							<passenger v-if="isShowPerson" :HotelSearchInfo="HotelSearchInfo" @bindComfirmPerson="bindComfirmPerson"></passenger>
						</div>
					</el-row>
					<!--</el-tab-pane>-->
					<!--</el-tabs>-->
					<div class="hotel-b2">
						<el-row type="flex">
							<div class="destination-m mr10">
								<div class="gray9 destination-a">目的地</div>
								<el-input v-model="HotelSearchInfo.CityName" placeholder="请输入内容" class="destina-con" id="scrollCity" @focus="bindShowCity($event)"
								 @input="searchInput" @click.stop.native></el-input>
								<!-- <place :desShow="desShow" @guanbi="guanbi"></place> -->
								<NewCity :showCity="showCity" ref="cityAssembly" hotcitytype="hotel" @clickCityDel="clickCityDel"
								 @bindSelectCity="bindSelectCity" :top="y" :left="x" @click.stop.native></NewCity>
							</div>

							<div class="destination-m mr10" style="width:45%">
								<el-row class="des-date" type="flex" justify="space-between" align="middle">
									<el-date-picker value-format="yyyy-MM-dd" v-model="dates" class="search-time" type="daterange" range-separator="至"
									 start-placeholder="入住时间" end-placeholder="退房时间" :picker-options="pickerOptions0"></el-date-picker>
									<!-- <el-date-picker
									    class="start-date8"
										value-format="yyyy-MM-dd"
										type="date"
										placeholder="yyyy-MM-dd"
										v-model="HotelSearchInfo.CheckInDateStr"
										clearable
										size="small"
										suffix-icon="iconfont icon-yqfrili"
										:picker-options="pickerOptions0"						
									></el-date-picker> 
									<span class="ft16">至</span>
									<el-date-picker
									   class="end-date8"
										value-format="yyyy-MM-dd"
										type="date"
										placeholder="yyyy-MM-dd"
										v-model="HotelSearchInfo.CheckOutDateStr"
										clearable
										size="small"
										suffix-icon="iconfont icon-yqfrili"
										:picker-options="pickerOptions1"						
									></el-date-picker> -->
								</el-row>
							</div>
							<div class="destination-m mr10">
								<div class="gray9 destination-a">关键词</div>
								<el-input v-model="HotelSearchInfo.KeyWord" @focus="bindShowKeyWordBox($event,true)" @input="searchKeyWordInput"
								 placeholder="请输入" class="destina-con" @click.stop.native></el-input>
								<keywords @click.stop.native ref="keywordsRef" :ShowKeyWordBox="ShowKeyWordBox" :cityCodeKeyWord="cityCodeKeyWord"
								 @bindShowKeyWordBox="bindShowKeyWordBox" @bindHideKeyWordBox="bindHideKeyWordBox" @selectKeyWord="selectKeyWord"></keywords>
							</div>
						</el-row>
					</div>
					<el-row type="flex" justify="center" class="history-search">
						<!-- <el-dropdown class="search-link">
							<el-link type="primary">搜索历史</el-link>
							<el-dropdown-menu slot="dropdown" class="place-item">
								<el-dropdown-item>巴厘岛 10.24-11.18</el-dropdown-item>
								<el-dropdown-item>上海 10.24-11.18</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown> -->
						<el-button type="danger" style="width:200px" @click="bindSearchHotel">搜索</el-button>
					</el-row>
				</el-form>
			</div>
		</div>

		<!-- <RecentOrders :ProductCategoryID="ProductCategoryID"></RecentOrders> -->
	</div>
</template>

<script>
	import city from '../../components/city/city.vue';
	// import RecentOrders from '../../components/RecentOrders.vue'; 
	// import place from './component/place.vue';
	import keywords from './component/keywords.vue';
	import passenger from './component/person.vue';
	import NewCity from '@/components/newCity/newCity.vue';
	import {
		adults,
		childrens
	} from '@/data/staticData';
	import {
		getHotelSearchInfo,
		setHotelSearchInfo
	} from '@/utils/localdatas';

	var datehelper = require('@/utils/datehelper.js');
	var util = require('@/utils/util.js');
	var hotelApi = require('@/api/hotelApi.js');
	var yqfApi = require('@/api/yqfApi.js');
	var that;

	export default {
		components: {
			NewCity,
			// RecentOrders,
			// place,
			keywords,
			passenger
		},
		data() {
			return {
				activeName: 'first',
				// data: (
				// 	<div class="hotel-i">
				// 		<div>
				// 			<span class="iconfont icon-jiudian1 i-jiu" />
				// 		</div>
				// 		<div class="i-jiudian"> 酒店 </div>{' '}
				// 	</div>
				// ),
				x: 0,
				y: 0,
				isFixedTop: false,
				latters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'W', 'X', 'Y', 'Z'],
				current: 1,
				desShow: 'false',
				ShowKeyWordBox: false,
				cityCodeKeyWord: '',
				isShowPerson: false,
				input: '',
				value1: '',
				input4: '',
				showCity: false,
				adults: adults,
				childrens: childrens,
				dates: [],
				HotelSearchInfo: {},
				filterStr: '',
				ProductCategoryID: 2,
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
				pickerOptions1: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				}
			};
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
		},
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);
			var HotelSearchInfo = getHotelSearchInfo();
			if (HotelSearchInfo == null) {
				getHotelSearchInfoJSON();
			} else {
				var date_now = new Date();
				var date_now_str = date_now.toString('yyyy-MM-dd');
				//如果出发日期小于今天
				if (!datehelper.compareDate(HotelSearchInfo.CheckInDateStr, date_now_str)) {
					HotelSearchInfo.CheckInDateStr = datehelper.DateTimeParse(date_now.addDays(1)).toString('yyyy-MM-dd');
					HotelSearchInfo.CheckOutDateStr = datehelper.DateTimeParse(date_now.addDays(2)).toString('yyyy-MM-dd');
				}

				that.HotelSearchInfo = HotelSearchInfo;
			}
			that.dates = [HotelSearchInfo.CheckInDateStr, HotelSearchInfo.CheckOutDateStr];
			console.log(HotelSearchInfo);

			// yqfApi.GetLocationByIP({}, function(result) {
			// 	// console.log(result);;
			// 	if (result.status == 0) {
			// 		that.HotelSearchInfo.Latitude = result.result.location.lat;
			// 		that.HotelSearchInfo.Longitude = result.result.location.lng;
			// 		console.log(that.HotelSearchInfo);
			// 		// GetAddressInfoByLocation(that.latitude, that.longitude);
			// 	}
			// });
		},
		methods: {
			// on: function(ev) {
			// 	alert(1)
			// 	ev.cancelBubble = true
			// },
			on2: function() {
				this.showCity = false;
				this.ShowKeyWordBox = false;
			},
			handleScroll() {
				var rect = document.querySelector('#scrollCity').getBoundingClientRect();

				this.y = rect.top;
				// console.log(window.pageYOffset + "1");
				// console.log(this.y);
			},
			searchInput(e) {
				console.log(e);
				that.$refs.cityAssembly.searchInput(e);
				// this.$refs.cityAssembly.$emit("searchInput",e);
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},

			bindHideKeyWordBox() {
				that.ShowKeyWordBox = false;
			},
			bindShowKeyWordBox(e, flag) {
				try {
					e.currentTarget.select();
				} catch (e) {

				}

				that.cityCodeKeyWord = that.HotelSearchInfo.Countrycode + that.HotelSearchInfo.Citycode;
				if (that.cityCodeKeyWord == "") {
					that.$alert('请先选择城市');
					return;
				}
				that.showCity = false;
				that.ShowKeyWordBox = flag;
				that.$refs.keywordsRef.bindGetHotelSearchLog(that.cityCodeKeyWord);
			},
			searchKeyWordInput(e) {
				console.log(e);
				that.$refs.keywordsRef.searchKeyWordInput(e);
				// this.$refs.cityAssembly.$emit("searchInput",e);
			},
			//选择关键字
			selectKeyWord(keyWord, filterStr) {
				console.log(keyWord);
				console.log(filterStr);
				// return;
				that.HotelSearchInfo.KeyWord = keyWord;
				that.filterStr = filterStr;
				that.ShowKeyWordBox = false;
			},
			desClick() {
				this.desShow = true;
			},

			guanbi() {
				this.desShow = false;
			},
			guanjianzi() {
				this.textShow = 'true';
			},
			guanbi2() {
				this.textShow = true;
			},
			bindShowPerson() {
				this.isShowPerson = !this.isShowPerson;
			},
			bindComfirm(e) {
				this.isShowPerson = false;
			},
			//选择城市
			bindShowCity(e) {
				e.cancelBubble = true;
				e.currentTarget.select();
				that.showCity = true;
				that.ShowKeyWordBox = false;
				var rect = e.target.getBoundingClientRect();
				this.x = rect.left
				this.y = rect.top;
				console.log(window.pageYOffset + "1");
				console.log(this.y);
			},
			clickCityDel() {
				this.showCity = false;
			},
			bindSelectCity(cityModel) {
				console.log(cityModel);
				this.HotelSearchInfo.CityName = cityModel.CityName;
				this.HotelSearchInfo.Citycode = cityModel.CityCode;
				this.HotelSearchInfo.Countrycode = cityModel.CountryCode;
				this.HotelSearchInfo.KeyWord = cityModel.KeyWord;
				this.showCity = false;
			},
			bindSearchHotel(e) {
				console.log(that.dates);
				that.HotelSearchInfo.CheckInDate = that.dates[0];
				that.HotelSearchInfo.CheckInDateStr = that.dates[0];
				that.HotelSearchInfo.CheckOutDate = that.dates[1];
				that.HotelSearchInfo.CheckOutDateStr = that.dates[1];

				hotelApi.GetHotelSearch({
					hotelsearchinfo: JSON.stringify(that.HotelSearchInfo)
				}, function(result) {
					// console.log(result);;
					if (result.code == 0) {
						that.HotelSearchInfo = result.data.HotelSearchInfo;

						setHotelSearchInfo(that.HotelSearchInfo);
						// that.$router.push({
						// 	path: '/Hotel/list'
						// });
						// console.log(that.fiterStr);
						// return;
						that.$router.push({
							path: '/Hotel/list',
							query: {
								filter: that.filterStr,
							}
						});
						// that.$router.push({ path: '/Hotel/list', query: { filter: that.filterStr } });
					} else {
						that.$message.error(result.msg);
					}
				});

				// that.$router.push({
				// 	path: '/Hotel/List'
				// });
				// console.log(that.HotelSearchInfo);
			},
			bindComfirmPerson(e) {
				that.isShowPerson = false;
			}
		}
	};

	//获取查询的信息
	function getHotelSearchInfoJSON() {
		hotelApi.GetHotelSearch(null, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.HotelSearchInfo = result.data.HotelSearchInfo;
				setHotelSearchInfo(that.HotelSearchInfo);
			}
		});
	}
</script>
<style lang="scss">
	@import '../../style/Hotel/hotel.scss';
</style>
