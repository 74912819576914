<template>
	<div class="hotel-passenger-box">
		<div class="indexv2-title mb10">旅客</div>
		<el-row type="flex" justify="space-between" align="middle" class="mb10">
			<span class="ft14">房间数</span>
			<el-input-number v-model="HotelSearchInfo.RoomCount" @change="handleChange" :min="1" :max="5" size="small"></el-input-number>
		</el-row>
		<el-row type="flex" justify="space-between" align="middle" class="mb10">
			<span class="ft14">成人（每间房入住人数）</span>
			<el-input-number v-model="HotelSearchInfo.AdultCount" @change="handleChange2" :min="1" :max="2" size="small"></el-input-number>
		</el-row>
		<el-row type="flex" justify="space-between" align="middle" class="mb10">
			<span class="ft14">儿童（每间房入住人数）</span>
			<el-input-number v-model="HotelSearchInfo.ChildCount" @change="handleChange2" :min="0" :max="3" size="small"></el-input-number>
		</el-row>
		<el-row class="mt20" type="flex" justify="center"><el-button type="danger" style="width:120px" @click="bindComfirmPerson">确定</el-button></el-row>
	</div>
</template>

<script>
export default {
	name: 'passenger',
	props: ['HotelSearchInfo'],
	data() {
		return {
			num: 1,
			numt: 2
		};
	},
	methods: {
		handleChange(value) {
			console.log(value);
		},
		handleChange2(value) {
			console.log(value);
		},
		bindComfirmPerson(e) {
			this.$emit('bindComfirmPerson');
		}
	}
};
</script>

<style></style>
